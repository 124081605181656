var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"box"},[_c('DateRangeV2',{attrs:{"start":_vm.duration.start,"end":_vm.duration.end,"dateSelect":"","periodToShow":_vm.periodToShow,"persistId":_vm.tableId},on:{"update:start":function($event){return _vm.$set(_vm.duration, "start", $event)},"update:end":function($event){return _vm.$set(_vm.duration, "end", $event)},"submit":function($event){return _vm.loadNotes()}}}),_c('CustomTable',{ref:"table",attrs:{"id_table":_vm.tableId,"primaryKey":"note_id","items":_vm.notes,"busy":_vm.table_busy,"hrefsRoutes":_vm.config_table_hrefs,"hideOperationActions":_vm.readOnly,"checkboxes":!_vm.readOnly,"externSlotColumns":['note_filetypes_trad', 'note_synthetize', 'categories'],"rawColumns":['note_comment']},on:{"update:busy":function($event){_vm.table_busy=$event}},scopedSlots:_vm._u([{key:"custom-slot-cell(note_filetypes_trad)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(Array.isArray(data.note_filetypes_trad) ? data.note_filetypes_trad.join(', ') : '')+" ")]}},{key:"custom-slot-cell(categories)",fn:function(ref){
var data = ref.data;
return [_vm._v(" "+_vm._s(data.categories.map(function (category) { return category.categorienote_libelle; }).join(', '))+" ")]}},{key:"custom-slot-cell(note_synthetize)",fn:function(ref){
var data = ref.data;
return [(data.note_synthetize && Object.keys(data.note_synthetize).length > 0)?_c('div',{staticClass:"previewColIdentifier d-inline"},_vm._l((data.note_synthetize.type_fonts),function(font,key){return _c('span',{key:key},[_c('font-awesome-icon',{attrs:{"icon":font.font}}),_vm._v(" "+_vm._s(font.occurences)+" ")],1)}),0):_vm._e()]}}],null,true)}),_c('div',[_c('b-modal',{attrs:{"id":"shareModal","hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('notes.linkToShare'))+" ")]},proxy:true}])},[_vm._l((_vm.noteToShare),function(note){return _c('div',{key:note.note_id},[_c('h3',[_vm._v(_vm._s(note.note_title))]),_vm._l((note.files),function(file,index){return _c('div',{key:file.files_id},[_c('e-copybox',{attrs:{"id":'shared-note-' + index,"label":file.files_original_name,"value":file.files_s3_url,"boxed":"","readonly":""}}),(Object.keys(note.files).length - 1 != index)?_c('hr'):_vm._e()],1)})],2)}),_c('b-button',{staticClass:"mt-3",attrs:{"block":""},on:{"click":function($event){return _vm.$bvModal.hide('shareModal')}}},[_vm._v(_vm._s(_vm.$t('notes.close')))])],2)],1),_c('div',[_c('b-modal',{ref:"choiceModal",attrs:{"hide-footer":"","size":"xl"},scopedSlots:_vm._u([{key:"modal-title",fn:function(){return [_vm._v(" "+_vm._s(_vm.$t('notes.add_note'))+" ")]},proxy:true}])},[_c('div',[_c('label',{attrs:{"for":"type"}},[_vm._v(_vm._s(_vm.$t('courrier_type.placeholder_type')))]),_c('e-select',{staticClass:"primary",attrs:{"id":"id","track-by":"id","label":"label","options":_vm.types,"show-labels":false,"allow-empty":false,"placeholder":_vm.$t('courrier_type.placeholder_type')},scopedSlots:_vm._u([{key:"singleLabel",fn:function(ref){
var option = ref.option;
return [_vm._v(_vm._s(option.label))]}}]),model:{value:(_vm.type_selected),callback:function ($$v) {_vm.type_selected=$$v},expression:"type_selected"}},[_c('template',{slot:"noOptions"},[_vm._v(_vm._s(_vm.$t('global.list_empty')))])],2)],1),_c('b-button',{staticClass:"btn btn-primary mt-3",attrs:{"variant":"primary","block":""},on:{"click":_vm.addNote}},[_vm._v(_vm._s(_vm.$t('global.suivant')))])],1)],1),_c('ModalSendNotes',{ref:"modal_send_notes"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }