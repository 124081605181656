<template>
	<div class="box">
		<DateRangeV2
			:start.sync="duration.start"
			:end.sync="duration.end"
			@submit="loadNotes()"
			dateSelect
			:periodToShow="periodToShow"
			:persistId="tableId"
		/>
		<CustomTable
			ref="table"
			:id_table="tableId"
			primaryKey="note_id"
			:items="notes"
			:busy.sync="table_busy"
			:hrefsRoutes="config_table_hrefs"
            :hideOperationActions="readOnly"
			:checkboxes="!readOnly"
			:externSlotColumns="['note_filetypes_trad', 'note_synthetize', 'categories']"
			:rawColumns="['note_comment']"
		>
			<template v-slot:[`custom-slot-cell(note_filetypes_trad)`]="{ data }">
				{{ Array.isArray(data.note_filetypes_trad) ? data.note_filetypes_trad.join(', ') : '' }}
			</template>

			<template v-slot:[`custom-slot-cell(categories)`]="{ data }">
				{{ data.categories.map((category) => category.categorienote_libelle).join(', ') }}
			</template>

            <template v-slot:[`custom-slot-cell(note_synthetize)`]="{ data }">
                <div 
                    v-if="data.note_synthetize && Object.keys(data.note_synthetize).length > 0" 
                    class="previewColIdentifier d-inline"
                >
					<span
						v-for="(font, key) in data.note_synthetize.type_fonts"
						:key="key"
					>
						<font-awesome-icon :icon="font.font" /> {{ font.occurences }}
					</span>
                </div>
            </template>
		</CustomTable>
		<div>
			<b-modal id="shareModal" hide-footer size="xl">
				<template #modal-title>
				{{ $t('notes.linkToShare')}}
				</template>
				<div v-for="note in noteToShare" :key="note.note_id">
					<h3>{{note.note_title}}</h3>
					<div v-for="(file, index) in note.files" :key="file.files_id">
						<e-copybox 
							:id="'shared-note-' + index"
							:label="file.files_original_name"
							:value="file.files_s3_url"
							boxed
							readonly
						/>
						<hr v-if="Object.keys(note.files).length - 1 != index"/>
					</div>
					

				</div>
				<b-button class="mt-3" block @click="$bvModal.hide('shareModal')">{{ $t('notes.close')}}</b-button>
			</b-modal>
		</div>
		<div>
			<b-modal ref="choiceModal" hide-footer size="xl">
				<template #modal-title>
				{{ $t('notes.add_note')}}
				</template>
				<div>
					<label for="type">{{ $t('courrier_type.placeholder_type') }}</label>
					<e-select
						v-model="type_selected"
						id="id"
						track-by="id"
						label="label"
						class="primary"
						:options="types"
						:show-labels="false"
						:allow-empty="false"
						:placeholder="$t('courrier_type.placeholder_type')"
					>
						<template slot="singleLabel" slot-scope="{ option }">{{ option.label }}</template>
						<template slot="noOptions">{{ $t('global.list_empty') }}</template>
					</e-select>
		    	</div>
				<b-button variant="primary" class="btn btn-primary mt-3" block @click="addNote">{{ $t('global.suivant')}}</b-button>
			</b-modal>
		</div>

		<ModalSendNotes ref="modal_send_notes"/>
	</div>
</template>


<script type="text/javascript">
    import NotesMixin from "@/mixins/Notes.js"
    import Navigation from "@/mixins/Navigation.js"

	export default {
		name: "NoteListe",
		mixins: [NotesMixin, Navigation],
		props: [
            'horse_id',
            'is_client_area',
			'tiers_id'
        ],
		data () {
			return {
				noteToShare: [],
				notes: [],
				table_busy: true,
				duration: {
					start: new Date(new Date().setMonth(new Date().getMonth() - 3)),
					end: new Date()
				},
				types: [
					{id: 1, label: this.getTrad('horse.horse')},
					{id: 2, label: this.getTrad('tiers.tiers')}
				],
				type_selected: null,

				events_tab: {
					"TableAction::goToAddNote": this.chooseType,
					"TableAction::goToAddHorseFicheNote": this.addHorseNote,
					"TableAction::goToDeleteNote": this.deleteSelectedNotes,
					"TableAction::goToShareNote": this.shareNote,
					"TableAction::goToAddTiersFicheNote": this.addTiersNote,
					"TableAction::goToSendNote": this.sendNotes
				},
				dateFilter: {
					column: 'note_date',
					start: null,
					end: null
				},
				periodToShow: ['personalise', 'semaine', 'semaine_derniere', 'jour', 'mois', 'mois_dernier', 'annee_en_cours'],

                /* Nom du cheval cliquable dans le tableau */
                config_table_hrefs: {
					'note_title': {
						routeUniqueName: 'notePreview',
                        routeName: 'notesManagement',
                        params: {
                            note_id: 'note_id'
                        }
					},
                    'horse.horse_nom': {
                        routeUniqueName: 'horseFiche',
                        routeName: 'horseFiche',
                        params: {
                            horse_id: 'horse.horse_id'
                        }
                    },
					'tiers.tiers_rs': {
                        routeUniqueName: 'tiersFiche',
                        routeName: 'tiersFiche',
                        params: {
                            tiers_id: 'tiers.tiers_id'
                        }
                    }
                }
			}
        },
		computed: {
			tableId() {
                if(this.is_client_area) {
					return this.horse_id ? 'horse_note_liste_ca' : 'note_liste_ca'
				}
				else if(this.horse_id) {
					return 'horse_note_liste'
				}
				else if(this.tiers_id) {
					return 'tiers_note_liste'
				}
				else {
					return 'note_liste'
				}
			},
            readOnly() {
                return this.$store.state.horseFicheReadonly
                || this.is_client_area
            }
		},
        created() {
            this.init_component()
		},
		methods: {
            async init_component() {
				if(this.horse_id) {
					this.duration.start = new Date(new Date().setFullYear(new Date().getFullYear() - 1))
				}
				this.loadNotes()
            },
            async loadNotes() {
                this.table_busy = true
            	if(this.horse_id) {
	                this.notes = await this.getHorseNotes(this.horse_id, this.duration.start.toDateInputValue(), this.duration.end.toDateInputValue())
				}
				else if(this.tiers_id) {
	                this.notes = await this.getTiersNotes(this.tiers_id, this.duration.start.toDateInputValue(), this.duration.end.toDateInputValue())
				}
				else {
	                this.notes = await this.getNotes(this.duration.start.toDateInputValue(), this.duration.end.toDateInputValue())
				}
                this.table_busy = false
            },
			chooseType() {
				this.$refs.choiceModal.show()
			},
			addNote() {
				if(this.type_selected && this.type_selected.id) {
					this.$refs.choiceModal.hide()
					this.$router.push({ name: 'notesManagement', params: { type: this.type_selected.id }})
				}
				else {
					this.failureToast("formulaire.erreur_champs_non_remplis")
				}
			},
			addHorseNote() {
				this.$router.push({ name: 'notesManagement', params: { horse_id: this.horse_id }})
			},
			addTiersNote() {
				this.$router.push({ name: 'notesManagement', params: { tiers_id: this.tiers_id }})
			},
			deleteSelectedNotes(ids) {
				this.deleteNotes(ids)
					.then(() => {
						return this.loadNotes()
					})
					.then(() => {
						this.$refs.table.refreshTable()
					})
			},
			async shareNote(ids){
				this.noteToShare = []
				for(const note of this.notes){
					if(ids.includes(note.note_id)){
						let noteToPush = note
						noteToPush.files = await this.getNoteFiles(note.note_id)
						this.noteToShare.push(noteToPush)
					}
				}
				this.OpenModal()
			},
			OpenModal(){
				this.$bvModal.show('shareModal')
			},
			CloseModal(){
				this.$bvModal.hide('shareModal')
			},
			sendNotes(ids) {
				this.$refs.modal_send_notes.openModal(ids, 'mail_notes', 'mail_notes')
			}
		},
		components: {
            CustomTable: () => import('GroomyRoot/components/Table/CustomTable'),
			DateRangeV2: () => import('GroomyRoot/components/Inputs/DateRangeV2'),
			ModalSendNotes: () => import('GroomyRoot/components/Modals/ModalSendNotes')
		}
	}
</script>
